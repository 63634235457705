.container {
  /* padding: 20px; */
  border-radius: 10px;
  background-color: var(--white);
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  gap: 10px;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-left: 15px;
  padding-right: 15px;
}
.img {
  width: 240px;
  height: 240px;
}
.img_text {
  color: var(--white);
  width: 150px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 30px;
  left: 40px;
}
.v_line {
  border: 1px dashed var(--pale-grey);
  align-self: stretch;
}
.code {
  font-size: 17px;
  color: var(--brown);
}
/* Media query for screens smaller than 600px (e.g., smartphones) */
@media (max-width: 600px) {
  .form_container {
    width: 250px;
  }
  .card_container {
    width: 250px;
  }
  .semi_item {
    width: 100%;
  }
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
  .semi_item {
    width: 100%;
  }
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
