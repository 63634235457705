.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.title {
  color: var(--errie-black);
  font-size: 20px;
  font-weight: 600;
}
.description {
  color: var(--placeholder-text);
  font-size: 14px;
  font-weight: 500;
}
.back {
  cursor: pointer;
  text-decoration: underline;
  color: var(--brown);
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 600px) {
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
