.primary-footer-container {
  display: flex;
  
  justify-content: space-between;
  gap: 30px;
  color: var(--brown);
  background-color: var(--off-white);
  padding-top: 40px;
  padding-bottom: 70px;
}
.section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}
.logo {
  max-height: 150px;
  display: grid;
  place-content: center;
  max-width: 300px;
  flex-shrink: 0;     /* Prevents shrinking */
  overflow: hidden;
}
.header {
  color: var(--errie-black);
  margin-bottom: 5px;
  font-weight: 450;
}
.link {
  text-decoration: none;
  color: var(--darkest-grey);
  cursor: pointer;
}
.link:hover {
  opacity: 0.6;
}
.email-container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  border: 1px solid var(--brown);
  padding: 8px 20px 8px 20px;
  gap: 10px;
}
.email-input {
  border: none;
  outline: none; /* Remove the default focus outline */
  background-color: transparent;
  color: var(--dark-grey);
  color: var(--brown);
  width: 100%;
  max-width: 250px;
}
::placeholder {
  color: var(--brown);
  opacity: 1; /* Firefox */
}
::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: var(--brown);
}
.secondary-footer-container {
  text-align: center;
  color: var(--white);
  background-color: var(--brown);
  padding: 14px 0;
}

@media (max-width: 600px) {
  .primary-footer-container {
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 35px;
  }
  .secondary-footer-container {
    font-size: 10px;
  }
  .section {
    gap: 5px;
  }
  .header {
    color: var(--brown);
    margin-bottom: 5px;
  }
  .link {
    color: var(--brown);
    cursor: pointer;
  }
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
  .secondary-footer-container {
    font-size: 12px;
  }
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
  .secondary-footer-container {
    font-size: 14px;
  }
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
  .secondary-footer-container {
    font-size: 18px;
  }
}
