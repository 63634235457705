.section {
  padding-block: 40px;
  padding-inline: 100px;

  & h1 {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
  }
}

.productsContainer {
  padding-top: 38px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  column-gap: 20px;
  row-gap: 40px;

  margin-bottom: 60px;

  & > div {
    margin-inline: auto;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.flashSale {
  margin-top: 48px;
}

@media (max-width: 767px) {
  .section {
    padding-inline: 20px;

    & h1 {
      display: none;
    }
  }
}
