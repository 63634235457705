.modalContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1.5rem;
  /* border-radius: 1.5rem; */
  width: 75vw;
  height: 70vh;
  overflow-y: auto;
}

.modalContent {
  /* padding: 1.25rem; */
  border: 1px solid #bbbbbb;
  border-radius: 0.75rem;
  width: 100%;
  height: 100%;
}

.closeButton {
  margin-left: auto;
  display: block;
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 1.25rem;
  cursor: pointer;
  background: none;
  border: none;
}

.content {
  text-align: center;
  padding-top: 1rem;
}

.title {
  font-size: 2rem;
  font-weight: 500;
  color: #354259;
  margin-bottom: 2rem;
}

.categoryList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 3rem;
  font-weight: 500;
  padding-inline: 2rem;
}

.categoryButton {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
}

.categoryButtonActive {
  color: #000;
}

.divider {
  height: 1rem;
  width: 1px;
  background-color: #d1d1d1;
}

.image {
  max-width: 100%;
  height: auto;
}

.loaderContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 60vw;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
}

.loader {
  font-size: 1.5rem;
  color: #000;
}
