.container {
  display: flex;
  flex-direction: column;
  /* width: 242px; */
  width: 100%;
}

.image-container {
  background-color: var(--pale-grey);
  /* width: 242px; */
  /* width: 100%;
  height: 242px; */
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
}

.title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
}
.description {
  color: var(--grey-text);
  font-size: 14px;
}

/* Media query for screens smaller than 600px (e.g., smartphones) */
@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .image-container {
    background-color: var(--pale-grey);
    /* width: 160px; */
    width: 100%;
    /* height: 160px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-container img {
    /* width: 80px; */
  }
  .title {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .description {
    color: var(--grey-text);
    font-size: 14px;
    height: 60px;
  }
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
