.announcement-bar {
  color: #121212 !important;
  background-color: #F6BA05;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 24px; /* set a fixed height to avoid layout shifts */
  display: flex;
  align-items: center;
  justify-content: center;
}

.announcement-bar p {
  margin: 0;
  color: #121212 !important;  /* 🔒 force white text */
  text-align: center;
  white-space: nowrap;
}

/* 🔁 Transition classes */
.banner-text-enter {
  opacity: 0;
  transform: translateY(50%);
}

.banner-text-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s ease-in-out;
}

.banner-text-exit {
  opacity: 1;
  transform: translateY(0);
}

.banner-text-exit-active {
  opacity: 0;
  transform: translateY(-50%);
  transition: all 1s ease-in-out;
}
