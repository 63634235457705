.container {
  background-color: var(--white);
  width: 500px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bottom_margin {
  margin-bottom: 10px;
}
.title {
  color: var(--errie-black);
  font-size: 20px;
  font-weight: 600;
}
.sub_title {
  color: var(--rhine-castle);
  font-size: 14px;
  font-weight: 400;
}
.label {
  font-size: 14px;
  font-weight: 600;
}
.error {
  color: var(--error);
}
.input {
  padding: 11px 14px 11px 14px;
  border: 1px solid var(--dreamy-cloud);
  border-radius: 8px;
}
.input::placeholder {
  color: var(--placeholder-text);
}
.checkbox {
  cursor: pointer;
  width: 15px;
}
.brown_button {
  cursor: pointer;
  color: var(--white);
  background-color: var(--brown);
  padding: 13px;
  border-radius: 10px;
  border: none;
}
.grey_button {
  cursor: pointer;
  color: var(--black);
  background-color: var(--dr-white);
  padding: 13px;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.footer_main {
  color: var(--rhine-castle);
}
.footer_sub {
  font-weight: bold;
  color: var(--brown);
  cursor: pointer;
}
.remember_me_line {
  display: flex;
  gap: 10px;
}
.remember_me_label {
  color: var(--rhine-castle);
}
.remember_me_link {
  margin-left: auto;
  cursor: pointer;
  color: var(--black);
}
.password_rules {
  font-size: 12px;
  color: #555;
  background: #f9f9f9;
  padding: 8px 12px;
  border-radius: 5px;
  margin-top: 5px;
  margin-left: 20px;

}

.password_rules ul {
  padding-left: 20px;
  list-style: disc;
}

.password_rules li {
  color: #6b6b6b;
  font-size: 13px;
  margin-bottom: 4px; /* Reduce vertical spacing */
  line-height: 1.4; /* Adjust line height for readability */
}
.password-requirements {
  position: absolute;
  right: 10px; /* Adjust to move further right */
  font-size: 12px; /* Reduce font size */
  list-style-type: none; /* Hide bullets */
  padding-left: 0; /* Remove default padding */
}
/* Media query for screens smaller than 600px (e.g., smartphones) */
@media (max-width: 600px) {
  .container {
    width: 250px;
  }
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
