.container {
  display: flex;
  justify-content: center;
  background-color: var(--dr-white);
}
.password_rules {
  font-size: 12px;
  color: #555;
  background: #f9f9f9;
  padding: 8px 12px;
  border-radius: 5px;
  margin-top: 5px;
  margin-left: 20px;

}

.password_rules ul {
  padding-left: 20px;
  list-style: disc;
}

.password_rules li {
  color: #6b6b6b;
  font-size: 13px;
  margin-bottom: 4px; /* Reduce vertical spacing */
  line-height: 1.4; /* Adjust line height for readability */
}
.password-requirements {
  position: absolute;
  right: 10px; /* Adjust to move further right */
  font-size: 12px; /* Reduce font size */
  list-style-type: none; /* Hide bullets */
  padding-left: 0; /* Remove default padding */
}
@media (max-width: 600px) {
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
